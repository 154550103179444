const deepMergeObjects = (target, source) => {
	if (typeof target !== "object" || typeof source !== "object") {
		throw new Error("Both arguments must be objects")
	}

	for (const key in source) {
		if (source.hasOwnProperty(key)) {
			if (source[key] instanceof Object) {
				if (!target.hasOwnProperty(key) || typeof target[key] !== "object") {
					target[key] = {}
				}
				deepMergeObjects(target[key], source[key])
			} else {
				target[key] = source[key]
			}
		}
	}
	return target
}

export default deepMergeObjects
